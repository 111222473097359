<template>
  <div style="min-height: 750px;" v-if="d_userShopBundleProductAgreementWdm">
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8"></b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-row style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
      <b-col sm="12" md="2" lg="2"> </b-col>
      <b-col sm="12" md="4" lg="4">
        <i class="fa fa-desktop"></i>
        {{ StoreLangTranslation.data['user_shop_bundle_product_agreement_record'][StoreLang] }}
      </b-col>
      <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'"></b-col>
      <b-col sm="12" md="2" lg="2"></b-col>
    </b-row>
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8">
        <b-row v-if="d_userShopBundleProductAgreementClientID" style="margin: 3px;">
          <b-col sm="12" lg="4">
          </b-col>
          <b-col sm="12" lg="8" style="text-align: right;">
            <b-button variant="warning" @click="f_agrementView()">
              {{ StoreLangTranslation.data['agreement_preview'][StoreLang] }}
            </b-button>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopBundleProductAgreementWdm['0']['parameters']['0']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleProductAgreementData.status">
              <option v-for="(op, op_index) in d_userShopBundleProductAgreementWdm['0']['parameters']['0'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>

        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopBundleProductAgreementWdm['0']['parameters']['1']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="date" class="form-control" v-model="d_userShopBundleProductAgreementData.update_date[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopBundleProductAgreementWdm['0']['parameters']['2']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopBundleProductAgreementData.document_code[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopBundleProductAgreementWdm['0']['parameters']['3']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleProductAgreementData.agreement_type">
              <option v-for="(op, op_index) in d_userShopBundleProductAgreementWdm['0']['parameters']['3'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>

        <b-row style="margin: 3px;">
          <b-col sm="12" lg="6">
          </b-col>
          <b-col sm="12" lg="6">
            <b-button variant="secondary" @click="f_addNewClauseItem()">
              yeni madde
            </b-button>
          </b-col>
        </b-row>
        <template v-if="d_userShopBundleProductAgreementData.clause_list.length > 0">
          <b-row v-for="(clause, clause_index) in d_userShopBundleProductAgreementData.clause_list" :style="f_calculateClauseStyle(clause_index)">
            <b-col sm="12" lg="12">
              <b-row style="margin: 3px;">
                <b-col sm="12" lg="10">
                  <strong>{{ clause_index + 1 }} )</strong>
                </b-col>
                <b-col sm="12" lg="2">
                  <b-button variant="danger" @click="f_deleteClauseItem(clause_index)">
                    {{ StoreLangTranslation.data['delete'][StoreLang] }}
                  </b-button>
                </b-col>
              </b-row>
              <b-row style="margin: 3px;">
                <b-col sm="12" lg="4">
                  {{ d_userShopBundleProductAgreementWdm['0']['parameters']['4']['parameters']['5']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8">
                  <select class="form-control" v-model="clause.itemization_level">
                    <option v-for="(op, op_index) in d_userShopBundleProductAgreementWdm['0']['parameters']['4']['parameters']['5'].options" :value="op.value">
                      {{ op.translation[StoreLang] }}
                    </option>
                  </select>
                </b-col>
              </b-row>
              <b-row style="margin: 3px;">
                <b-col sm="12" lg="4">
                  {{ d_userShopBundleProductAgreementWdm['0']['parameters']['4']['parameters']['6']['name']['translation'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="8"></b-col>
              </b-row>
              <b-row style="margin: 3px;">
                <b-col sm="12" lg="12">
                  <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                    <b-col sm="12" lg="1">
                      {{ lang.label }}
                    </b-col>
                    <b-col sm="12" lg="11">
                      <b-form-textarea v-model="clause.info[lang.label]"></b-form-textarea>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="6">
          </b-col>
          <b-col sm="12" lg="6">
            <b-button variant="secondary" @click="f_userShopBundleProductAgreementRecord()">
              {{ StoreLangTranslation.data['save'][StoreLang] }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-modal v-if="d_agreementModal.show" id="d_agreementModal_show" v-model="d_agreementModal.show" scrollable hide-footer :title="StoreLangTranslation.data['agreement_preview'][StoreLang]" centered header-bg-variant="info" header-text-variant="dark" size="xl">
      <p>
        <b-row>
          <b-col sm="12" lg="12">
            <agreement-view :p_shopData="d_agreementModal.shop_data" :p_bundleProductData="d_agreementModal.bundle_product_data"></agreement-view>
          </b-col>
        </b-row>
      </p>
    </b-modal>
  </div>
</template>

<script>
import { ClsWdm } from '@/wam/modules/wdm';
import { ClsRoute } from '@/wam/modules/route';
import store from '@/store';
import { ServiceFunctions } from '@/wam/modules/service';
import {
  default as AgreementView
} from '@/wam/components/widgets/AgreementView';
import { mapGetters } from 'vuex';
export default {
  name: 'user_shop_bundle_product_agreement_record',
  computed: mapGetters({
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreDevice: 'StoreDevice',
  }),
  components: {
    AgreementView,
  },
  props: {},
  data () {
    return {
      d_agreementModal: {
        'show': false,
        'shop_data': {},
        'bundle_data': {},
        'bundle_product_data': {},
      },
      d_user: '',
      d_frontendClientID: '',
      d_userShopClientID: '',
      d_userShopBundleProductClientID: '',
      d_userShopBundleClientID: '',
      d_userShopBundleProductAgreementClientID: '',
      d_frontendList: [],
      d_userShopBundleProductAgreementWdm: '',
      d_userShopBundleProductAgreementData: {
        'status': '0',
        'update_date': {},
        'document_code': {},
        'agreement_type': '1',
        'clause_list': [],
      }
    };
  },
  created: function () {
    ClsWdm.getLayerWdm('usr_shp_bund_pro_agr').then(resp_wdm_data => { this.d_userShopBundleProductAgreementWdm = resp_wdm_data; });
    this.d_user = JSON.parse(localStorage.getItem('user'));
  },
  beforeMount () {},
  mounted: function () {
    if (this.$route.query.user_shop_client_id) {
      this.d_userShopClientID = this.$route.query.user_shop_client_id;
    }
    if (this.$route.query.user_shop_bundle_product_client_id) {
      this.d_userShopBundleProductClientID = this.$route.query.user_shop_bundle_product_client_id;
    }
    if (this.$route.query.user_shop_bundle_client_id) {
      this.d_userShopBundleClientID = this.$route.query.user_shop_bundle_client_id;
    }
    if (this.$route.query.user_shop_bundle_product_agreement_client_id) {
      this.d_userShopBundleProductAgreementClientID = this.$route.query.user_shop_bundle_product_agreement_client_id;
    }
    if (this.d_userShopBundleProductAgreementClientID) {
      this.f_userShopBundleProductAgreementGet();
    }
    if (!this.d_userShopClientID) {
      alert(this.StoreLangTranslation.data['shop_is_not_selected'][this.StoreLang]);
      this.f_goToShopList();
    }
    if (!this.d_userShopBundleClientID) {
      alert(this.StoreLangTranslation.data['bundle_is_not_selected'][this.StoreLang]);
      this.f_goToShopList();
    }
    if (!this.d_userShopBundleProductAgreementClientID) {
      this.f_langEligibleParameterProcess();
    }
    // console.log(this.StoreLangList);
    // console.log(this.StoreFrontendSettings);
    // console.log(this.d_userShopBundleProductAgreementWdm);
  },
  methods: {
    f_agrementView: function () {
      this.d_agreementModal.shop_data = {'client_id': this.d_userShopClientID };
      this.d_agreementModal.bundle_product_data = {'client_id': this.d_userShopBundleProductClientID, 'agreement_client_id': this.d_userShopBundleProductAgreementClientID };
      this.d_agreementModal.show = true;
    },
    f_deleteClauseItem: function (clause_index) {
      this.d_userShopBundleProductAgreementData.clause_list.splice(clause_index, 1);
    },
    f_addNewClauseItem: function () {
      let lang_eligible_variables = ['info'];
      let new_item = { 'itemization_level': '', 'info': {} };
      for (let k in lang_eligible_variables) {
        for (let lang_index in this.StoreLangList.data) {
          new_item[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].label] = '';
        }
      }
      this.d_userShopBundleProductAgreementData.clause_list.push(new_item);
    },
    f_calculateClauseStyle: function (clause_index) {
      let style = 'margin: 3px;';
      if (clause_index % 2 === 0) {
        style += 'background-color: #99e4e8';
      } else {
        style += 'background-color: #99e4e8';
        // style += 'background-color: white;';
      }
      return style;
    },
    f_goToShopList: function () {
      this.$router.push({ path: ClsRoute.f_getRoutePath('user_shop_list', this.StoreLang), query: {} });
    },
    f_langEligibleParameterProcess: function () {
      let lang_eligible_variables = ['update_date', 'document_code'];
      for (let k in lang_eligible_variables) {
        for (let lang_index in this.StoreLangList.data) {
          if (!this.d_userShopBundleProductAgreementData[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].label]) {
            this.d_userShopBundleProductAgreementData[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].label] = '';
          }
        }
      }
    },
    f_userShopBundleProductAgreementRecord: function () {
      let query = 'user_client_id=' + this.d_user.username;
      let data = {
        'agreement_data': this.d_userShopBundleProductAgreementData
      };
      if (this.d_userShopBundleProductClientID) {
        query += '&user_shop_bundle_product_client_id=' + this.d_userShopBundleProductClientID;
      }
      if (this.d_userShopBundleProductAgreementClientID) {
        query += '&user_shop_bundle_product_agreement_client_id=' + this.d_userShopBundleProductAgreementClientID;
      }
      if (this.d_userShopBundleProductAgreementClientID) {
        ServiceFunctions.user_shop_bundle_product_agreement_edit(query, data)
          .then(resp => {
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            } else {
              alert(resp.data.status_code + ' = ' + resp.data.status_message);
            }
          });
      } else {
        ServiceFunctions.user_shop_bundle_product_agreement_new(query, data)
          .then(resp => {
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            } else {
              alert(resp.data.status_code + ' = ' + resp.data.status_message);
            }
          });
      }
    },
    f_userShopBundleProductAgreementGet: function () {
      let query = 'user_shop_bundle_product_agreement_client_id=' + this.d_userShopBundleProductAgreementClientID;
      query += '&data_type=value_mode';
      let data = {};
      ServiceFunctions.user_shop_bundle_product_agreement_get(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.data) {
              this.d_userShopBundleProductAgreementData = resp.data.data;
              this.f_langEligibleParameterProcess();
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
  }
};

</script>

